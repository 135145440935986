import React, { useState, useEffect, useCallback } from 'react';
import { ExternalLink } from 'lucide-react';

const EmployeesPage = () => {
  const [employees, setEmployees] = useState([]);
  const [parents, setParents] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [documentos, setDocumentos] = useState([]);
  const [capacitaciones, setCapacitaciones] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const employeesData = process.env.REACT_APP_EMPLOYEES;
      const parentsData = process.env.REACT_APP_PARENTS;

      if (employeesData) {
        const parsedEmployees = JSON.parse(employeesData);
        setEmployees(parsedEmployees);

        const storedPhone = localStorage.getItem('phone');
        if (storedPhone) {
          const employee = parsedEmployees.find(emp => emp.phone === storedPhone);
          if (employee) {
            setIsLoggedIn(true);
            setCurrentEmployee(employee);
          }
        }
      }

      if (parentsData) {
        const parsedParents = JSON.parse(parentsData);
        setParents(parsedParents);
      }

      // Cargar documentos y capacitaciones
      const response = await fetch('/data/blog/documentosempleados.json');
      const data = await response.json();
      setDocumentos(data.documentos);
      setCapacitaciones(data.capacitaciones);
    } catch (error) {
      console.error("Error al cargar datos:", error);
      setErrorMessage("Error al cargar datos. Por favor, intente nuevamente más tarde.");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLogin = useCallback((e) => {
    e.preventDefault();
    const employee = employees.find(emp => emp.phone === phone && emp.password === password);
    if (employee) {
      setIsLoggedIn(true);
      setErrorMessage('');
      setCurrentEmployee(employee);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('phone', phone);
    } else {
      setErrorMessage('Credenciales incorrectas. Inténtalo de nuevo.');
    }
  }, [employees, phone, password]);

  const handleLogout = useCallback(() => {
    setIsLoggedIn(false);
    setPhone('');
    setPassword('');
    setCurrentEmployee(null);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('phone');
  }, []);

  return (
    <div className="max-w-5xl mx-auto p-6 py-12 lg:px-24">
      {!isLoggedIn ? (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-4">
            <h2 className="text-3xl font-bold mb-6">Inicio de Sesión de Empleados</h2>
            <form onSubmit={handleLogin}>
              {errorMessage && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                  <span className="block sm:inline">{errorMessage}</span>
                </div>
              )}
              <div className="mb-4">
                <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">
                  Teléfono
                </label>
                <input
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                  Contraseña
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Iniciar Sesión
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-4">
            <div className='flex flex-col lg:flex-row gap-3'>
              <h2 className="text-3xl font-bold mb-6">Panel de Control de {currentEmployee.fullName}</h2>
              <button
                onClick={handleLogout}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-6"
              >
                Cerrar Sesión
              </button>
            </div>

            <div className="bg-gray-100 rounded-lg p-4 mb-6">
              <h3 className="font-bold text-xl mb-2">Información del Empleado</h3>
              <p><strong>Nombre:</strong> {currentEmployee.fullName}</p>
              <p><strong>Teléfono:</strong> {currentEmployee.phone}</p>
            </div>
            <div className="mb-6">
              <h3 className="text-xl font-bold mb-2">Documentos</h3>
              <ul className="space-y-2">
                {documentos.map((doc) => (
                  <li className='border px-4 py-2 rounded-lg shadow-sm' key={doc.id}>
                    <a
                      href={doc.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 flex items-center"
                    >
                      {doc.nombre}
                      <ExternalLink size={16} className="ml-1" />
                    </a>
                    <p className="text-sm text-gray-600">{doc.descripcion}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-6">
              <h3 className="text-xl font-bold mb-2">Capacitaciones</h3>
              <ul className="space-y-2">
                {capacitaciones.map((cap) => (
                  <li className='border px-4 py-2 rounded-lg shadow-sm' key={cap.id}>
                    <a
                      href={cap.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 flex items-center"
                    >
                      {cap.nombre}
                      <ExternalLink size={16} className="ml-1" />
                    </a>
                    <p className="text-sm text-gray-600">{cap.descripcion}</p>
                    <p className="text-sm text-gray-600">Duración: {cap.duracion}</p>
                    <p className="text-sm text-gray-600">Fecha de inicio: {cap.fechaInicio}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Lista de Padres de Familia</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="py-2 px-4 border-b">Nombre de la Familia</th>
                      <th className="py-2 px-4 border-b">Teléfono</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parents.map((parent, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="py-2 px-4 border-b">{parent.family}</td>
                        <td className="py-2 px-4 border-b">{parent.phone}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeesPage;