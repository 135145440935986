import React, { useState, useEffect, useCallback } from 'react';
import { ExternalLink } from 'lucide-react';

const ParentsPage = () => {
  const [parents, setParents] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentParent, setCurrentParent] = useState(null);
  const [documentos, setDocumentos] = useState([]);
  const [actividades, setActividades] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const parentsData = process.env.REACT_APP_PARENTS;
      if (parentsData) {
        const parsedParents = JSON.parse(parentsData);
        setParents(parsedParents);

        const storedPhone = localStorage.getItem('parentPhone');
        if (storedPhone) {
          const parent = parsedParents.find(p => p.phone === storedPhone);
          if (parent) {
            setIsLoggedIn(true);
            setCurrentParent(parent);
          }
        }
      }

      // Cargar documentos y actividades para padres
      const response = await fetch('/data/blog/documentospadres.json');
      const data = await response.json();
      setDocumentos(data.documentos);
      setActividades(data.actividades);
    } catch (error) {
      console.error("Error al cargar datos:", error);
      setErrorMessage("Error al cargar datos. Por favor, intente nuevamente más tarde.");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLogin = useCallback((e) => {
    e.preventDefault();
    const parent = parents.find(p => p.phone === phone && p.password === password);
    if (parent) {
      setIsLoggedIn(true);
      setErrorMessage('');
      setCurrentParent(parent);
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('parentPhone', phone);
    } else {
      setErrorMessage('Credenciales incorrectas. Inténtalo de nuevo.');
    }
  }, [parents, phone, password]);

  const handleLogout = useCallback(() => {
    setIsLoggedIn(false);
    setPhone('');
    setPassword('');
    setCurrentParent(null);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('parentPhone');
  }, []);

  return (
    <div className="max-w-5xl mx-auto p-6">
      {!isLoggedIn ? (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-4">
            <h2 className="text-3xl font-bold mb-6">Inicio de Sesión de Padres</h2>
            <form onSubmit={handleLogin}>
              {errorMessage && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                  <span className="block sm:inline">{errorMessage}</span>
                </div>
              )}
              <div className="mb-4">
                <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">
                  Teléfono
                </label>
                <input
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-6">
                <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                  Contraseña
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Iniciar Sesión
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-4">
            <div className='flex flex-col lg:flex-row gap-3'>
              <h2 className="text-3xl font-bold mb-6">Panel de Control de la Familia {currentParent.family}</h2>
              <button
                onClick={handleLogout}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-6"
              >
                Cerrar Sesión
              </button>
            </div>
            <div className="bg-gray-100 rounded-lg p-4 mb-6">
              <h3 className="font-bold text-xl mb-2">Información de la Familia</h3>
              <p><strong>Familia:</strong> {currentParent.family}</p>
              <p><strong>Teléfono:</strong> {currentParent.phone}</p>
            </div>
            <div className="mb-6">
              <h3 className="text-xl font-bold mb-2">Documentos para Padres</h3>
              <ul className="space-y-2">
                {documentos.map((doc) => (
                  <li className='border px-4 py-2 rounded-lg shadow-sm' key={doc.id}>
                    <a
                      href={doc.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 flex items-center"
                    >
                      {doc.nombre}
                      <ExternalLink size={16} className="ml-1" />
                    </a>
                    <p className="text-sm text-gray-600">{doc.descripcion}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-6">
              <h3 className="text-xl font-bold mb-2">Actividades y Eventos</h3>
              <ul className="space-y-2">
                {actividades.map((act) => (
                  <li className='border px-4 py-2 rounded-lg shadow-sm' key={act.id}>
                    <h4 className="font-semibold">{act.nombre}</h4>
                    <p className="text-sm text-gray-600">{act.descripcion}</p>
                    <p className="text-sm text-gray-600">Fecha: {act.fecha}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ParentsPage;