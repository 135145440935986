import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeroSection from '../components/HeroSection';

const Register = () => {
  const { t } = useTranslation();
  const [familyData, setFamilyData] = useState({
    parentName: '',
    email: '',
    phone: '',
    address: '',
    childName: '',
    childAge: '',
    childBirthdate: '',
    notes: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFamilyData({
      ...familyData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Registro enviado:', familyData);
    setFamilyData({
      parentName: '',
      email: '',
      phone: '',
      address: '',
      childName: '',
      childAge: '',
      childBirthdate: '',
      notes: ''
    });
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('register.title')}
            description={t('register.heroText')}
          />
        </div>
      </section>
      
      {/* Formulario de registro */}
      <section className="p-8 bg-gray-100 rounded-lg shadow-lg container mx-auto lg:px-64 lg:py-32">
        <form className="space-y-4" onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold text-sky-700 mb-6">{t('register.familyInfo')}</h2>
          <input
            type="text"
            name="parentName"
            placeholder={t('register.parentName')}
            value={familyData.parentName}
            onChange={handleChange}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
          />
          <input
            type="email"
            name="email"
            placeholder={t('register.email')}
            value={familyData.email}
            onChange={handleChange}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
          />
          <input
            type="tel"
            name="phone"
            placeholder={t('register.phone')}
            value={familyData.phone}
            onChange={handleChange}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
          />
          <input
            type="text"
            name="address"
            placeholder={t('register.address')}
            value={familyData.address}
            onChange={handleChange}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
          />

          <h2 className="text-3xl font-bold text-sky-700 mt-8 mb-6">{t('register.childInfo')}</h2>
          <input
            type="text"
            name="childName"
            placeholder={t('register.childName')}
            value={familyData.childName}
            onChange={handleChange}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
          />
          <input
            type="number"
            name="childAge"
            placeholder={t('register.childAge')}
            value={familyData.childAge}
            onChange={handleChange}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
          />
          <input
            type="date"
            name="childBirthdate"
            value={familyData.childBirthdate}
            onChange={handleChange}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
          />
          <textarea
            name="notes"
            placeholder={t('register.notes')}
            value={familyData.notes}
            onChange={handleChange}
            rows="4"
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
          />
          <button type="submit" className="bg-sky-600 text-white py-3 px-6 rounded-lg hover:bg-sky-500 transition duration-300">
            {t('register.registerButton')}
          </button>
        </form>
      </section>
    </div>
  );
};

export default Register;
