import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeroSection from '../components/HeroSection';
import { Star } from 'lucide-react';

const Testimonials = () => {
  const { t, i18n } = useTranslation();
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch('/data/blog/testimonials.json');
        if (!response.ok) throw new Error('Error al cargar los testimonios');
        const data = await response.json();
        setTestimonials(data.testimonials);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTestimonials();
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('testimonials.title')}
            description={t('testimonials.description')}
          />
        </div>
      </section>

      {/* Listado de testimonios */}
      <section className="max-w-6xl mx-auto grid md:grid-cols-2 gap-8 px-6 py-16">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="bg-white p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl text-center"
          >
            <p className="text-gray-600 italic mb-4">{testimonial.text[i18n.language]}</p>
            <p className="font-semibold">{testimonial.author[i18n.language]}</p>
            <div className='flex flex-row gap-2 items-center justify-center pt-6 text-orange-400'>
              <Star className="h-8 w-8" />
              <Star className="h-8 w-8" />
              <Star className="h-8 w-8" />
              <Star className="h-8 w-8" />
              <Star className="h-8 w-8" />
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Testimonials;
