import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUsers, FaBriefcase, FaPhone } from 'react-icons/fa'; // Asegúrate de tener los iconos que usarás
import { useTranslation } from 'react-i18next';

const AreaCard = ({ icon: Icon, title, description, color, link }) => (
  <Link to={link} className={`bg-white border-b-8 border-b-red-400 rounded-xl shadow-lg p-6 flex flex-col items-center transition-all duration-300 hover:shadow-2xl hover:-translate-y-2 cursor-pointer ${color}`}>
    <Icon size={48} className="mb-4 text-sky-700" />
    <h3 className="text-xl font-bold mb-2 text-gray-800">{title}</h3>
    <p className="text-justify text-gray-600 text-lg">{description}</p>
  </Link>
);

const AreasAcceso = () => {
  const [activities, setActivities] = useState([]);
  const { i18n } = useTranslation();
  const language = i18n.language; // Obtener el idioma actual ("es" o "en")

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch('/data/blog/accesos.json');
        if (!response.ok) throw new Error('Error al cargar las áreas de acceso');
        const data = await response.json();
        setActivities(data.activities);
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivities();
  }, []);

  return (
    <div className="bg-gradient-to-br from-blue-50 via-purple-50 to-pink-50 py-24 border-t-2 border-b-2 border-sky-300 px-4">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12 text-indigo-700">{language === 'es' ? 'Acceso a Áreas' : 'Access Areas'}</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {activities.map((activity, index) => {
            const Icon = require('react-icons/fa')[activity.icon]; // Obtener el icono según el nombre
            const link = index === 0 ? '/parents' : index === 1 ? '/employees' : '/contact'; // Rutas correspondientes
            return (
              <AreaCard
                key={index}
                icon={Icon}
                title={activity.title[language]}
                description={activity.description[language]}
                color={`hover:bg-${index % 3 === 0 ? 'blue' : index % 3 === 1 ? 'green' : 'pink'}-100`} // Colores alternos
                link={link}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AreasAcceso;
