import React from 'react';
import { FaBaby, FaPuzzlePiece, FaChalkboardTeacher, FaAppleAlt, FaUsers } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción
import HeroSection from '../components/HeroSection';

const Services = () => {
  const { t } = useTranslation(); // Inicializa el hook de traducción

  const services = [
    {
      icon: <FaBaby className="text-4xl text-yellow-500 mb-4" />,
      title: t('services.childCare.title'),
      description: t('services.childCare.description'),
    },
    {
      icon: <FaPuzzlePiece className="text-4xl text-yellow-500 mb-4" />,
      title: t('services.educationalActivities.title'),
      description: t('services.educationalActivities.description'),
    },
    {
      icon: <FaChalkboardTeacher className="text-4xl text-yellow-500 mb-4" />,
      title: t('services.earlyDevelopment.title'),
      description: t('services.earlyDevelopment.description'),
    },
    {
      icon: <FaAppleAlt className="text-4xl text-yellow-500 mb-4" />,
      title: t('services.nutritiousMeals.title'),
      description: t('services.nutritiousMeals.description'),
    },
    {
      icon: <FaUsers className="text-4xl text-yellow-500 mb-4" />,
      title: t('services.parentSupport.title'),
      description: t('services.parentSupport.description'),
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('services.title')}
            description={t('services.description')}
          />
        </div>
      </section>
      
      {/* Detalle de los servicios */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div key={index} className="bg-white p-8 rounded-lg shadow-lg transition-all duration-300 transform hover:-translate-y-2 hover:shadow-xl">
                {service.icon}
                <h2 className="text-2xl font-semibold text-sky-600 mb-4">{service.title}</h2>
                <p className="text-gray-600">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

    </div>
  );
};

export default Services;