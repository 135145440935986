import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout'; 
import ScrollToTop from './components/ScrollToTop'; // Importa el nuevo componente ScrollToTop
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Jobs from './pages/Jobs';
import Activities from './pages/Activities';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Testimonials from './pages/Testimonials';
import Register from './pages/Register';
import ArticleList from './components/ArticleList';
import ArticleDetail from './components/ArticleDetail';
import EmployeesPage from './pages/EmployeesPage';
import ParentsPage from './pages/ParentsPage';

function App() {
  return (
    <Router>
      <Layout>
        <ScrollToTop /> {/* Añadir el componente aquí */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/register" element={<Register />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/employees" element={<EmployeesPage />} />
          <Route path="/parents" element={<ParentsPage />} />
          <Route path="/" element={<ArticleList />} />
          <Route path="/articles/:id" element={<ArticleDetail />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
