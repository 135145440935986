import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 5;
  const { i18n } = useTranslation();
  const language = i18n.language; // Obtener el idioma actual ("es" o "en")

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch('/data/blog/articles.json');
        if (!response.ok) throw new Error('Error al cargar los artículos');
        const data = await response.json();
        setArticles(data.articles);
      } catch (error) {
        console.error(error);
      }
    };

    fetchArticles();
  }, []);

  // Calcular los artículos a mostrar en la página actual
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  // Cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="max-w-6xl mx-auto p-6">
      {/* Título de la sección */}
      {/* <h2 className="text-3xl font-bold mb-6 text-center">Lista de Artículos</h2> */}

      {/* Lista de artículos */}
      <ul className="space-y-6 text-lg">
        {currentArticles.map((article) => (
          <li key={article.id} className="bg-white shadow-lg hover:shadow-xl transition-shadow rounded-lg p-6">
            <Link to={`/articles/${article.id}`} className="text-2xl lg:text-4xl font-semibold text-blue-700 hover:underline">
              {article.title[language]}
            </Link>
            <div className="w-32">
                <p className="text-sm text-gray-600 mt-2 border px-1 py-2 text-center rounded-sm">{article.date}</p>
            </div>
            <p className="text-gray-600 mt-4">
              {article.content.introduction[language]}
            </p>
          </li>
        ))}
      </ul>

      {/* Paginación */}
      <div className="flex justify-center items-center mt-8 space-x-4">
        {Array.from({ length: Math.ceil(articles.length / articlesPerPage) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            aria-label={`Ir a la página ${index + 1}`}
            className={`px-4 py-2 rounded-full text-white transition-colors ${
              currentPage === index + 1
                ? 'bg-blue-700 hover:bg-blue-800'
                : 'bg-gray-500 hover:bg-gray-600'
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ArticleList;
