import React, { useState, useEffect } from 'react';
import { X, Sun, Cloud, Star, Heart } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const GaleriaImagenes = () => {
  const { t } = useTranslation();
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
  const [imagenes, setImagenes] = useState([]);

  // Cargar las imágenes desde el archivo JSON
  useEffect(() => {
    fetch('/img/imagenes.json')
      .then((response) => response.json())
      .then((data) => setImagenes(data.images)) // Suponiendo que las imágenes están en un array llamado "images"
      .catch((error) => console.error('Error al cargar las imágenes:', error));
  }, []);

  return (
    <div className="bg-gradient-to-b from-blue-100 to-green-100 py-24 px-4 border-t-2 border-b-2 border-t-green-500 border-b-sky-400">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-center mb-8 text-indigo-600 relative">
          {t('home.gallery.title')}
          <span className="absolute -top-6 -left-6 text-yellow-400"><Sun size={100} /></span>
          <span className="absolute -top-6 -right-6 text-blue-400"><Cloud size={100} /></span>
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {imagenes.map((imagen, index) => (
            <div key={index} className="relative group" onClick={() => setImagenSeleccionada(imagen)}>
              <img
                src={`/img/${imagen}`} // Adaptar para usar la ruta del JSON
                alt={`Imagen ${index + 1}`}
                className="w-full h-64 object-cover rounded-2xl shadow-lg group-hover:shadow-xl transition duration-300 border-4 border-white"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-indigo-500 via-transparent to-transparent opacity-0 group-hover:opacity-70 transition duration-300 rounded-2xl"></div>
              <p className="absolute bottom-4 left-4 text-white font-semibold opacity-0 group-hover:opacity-100 transition duration-300">{`Imagen ${index + 1}`}</p>
            </div>
          ))}
        </div>
      </div>

      {imagenSeleccionada && (
        <div className="fixed inset-0 bg-indigo-900 bg-opacity-90 flex items-center justify-center z-50">
          <div className="max-w-4xl max-h-full p-8 relative">
            <img
              src={`/img/${imagenSeleccionada}`} // Mostrar imagen seleccionada
              alt="Imagen seleccionada"
              className="max-w-full max-h-[80vh] object-contain rounded-lg border-8 border-white shadow-2xl"
            />
            <p className="text-white text-xl mt-4 text-center">{imagenSeleccionada}</p>
            <button
              onClick={() => setImagenSeleccionada(null)}
              className="absolute top-2 right-2 text-white hover:text-yellow-300 transition duration-300"
            >
              <X size={32} />
            </button>
            <Star className="absolute top-4 left-4 text-yellow-400" size={24} />
            <Heart className="absolute bottom-4 right-4 text-pink-400" size={24} />
          </div>
        </div>
      )}
    </div>
  );
};

export default GaleriaImagenes;
