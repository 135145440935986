import { Cloud, Sun } from 'lucide-react';
import React from 'react';

const HeroSection = ({ title, description }) => {
  return (
    <section className="relative overflow-hidden">
      <div className="bg-gradient-to-r from-blue-300 to-green-300 flex flex-col items-center justify-center p-4 text-white py-10 relative z-10">
        <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white relative">
          {title}
          {/* Iconos posicionados con transiciones y rotación */}
          <span className="absolute opacity-50 -top-10 lg:-top-20 -left-30 lg:-left-72 text-yellow-400 transform transition-transform duration-500 hover:rotate-12">
            <Sun size={150} />
          </span>
        </h1>
        <p className="text-xl max-w-3xl mx-auto mb-8 text-sky-900">
          {description}
        </p>
      </div>

      {/* Elementos decorativos para darle más vida a la sección */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent to-blue-500 opacity-40 pointer-events-none"></div>
    </section>
  );
};

export default HeroSection;
