import React from 'react';
import { MapPin, Phone, Mail, Facebook, Instagram, Linkedin, Sun, Cloud, Star } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const FooterLink = ({ href, children }) => (
  <a href={href} className="hover:text-yellow-400 transition-colors duration-300 flex items-center">
    <Star className="mr-2 h-4 w-4" />
    {children}
  </a>
);

const SocialIcon = ({ icon: Icon, href }) => (
  <a href={href} className="bg-indigo-700 p-2 rounded-full text-white hover:bg-yellow-400 transition-all duration-300 transform hover:scale-110">
    <Icon size={20} />
  </a>
);

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-gradient-to-b from-indigo-800 to-indigo-900 text-gray-300 relative overflow-hidden">
      {/* Elementos decorativos */}
      <div className="absolute top-0 left-0 text-indigo-100 opacity-50">
        <Sun size={100} />
      </div>
      <div className="absolute bottom-0 right-0 text-indigo-100 opacity-50">
        <Cloud size={100} />
      </div>

      {/* Mapa */}
      <section className='w-full border-0'>
        <h2 className="text-2xl lg:text-4xl font-black px-8 py-6 text-white text-center">{t('contact.mapTitle')}</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3013.0353547786144!2d-75.99756402342679!3d40.95880432246891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c5a38a3092afe7%3A0x42d222235eefb4c6!2s30%20W%20Broad%20St%2C%20West%20Hazleton%2C%20PA%2018202%2C%20EE.%20UU.!5e0!3m2!1ses!2sco!4v1727799717513!5m2!1ses!2sco"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>

      <div className="max-w-6xl mx-auto px-4 py-12 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Columna 1: Sobre Nosotros */}
          <div className="space-y-4">
            <h3 className="text-2xl font-bold text-yellow-400 mb-4">{t('contact.infoOrganization')}</h3>
            <p className="text-gray-300">
              {t('footer.description')}
            </p>
            <div className="flex space-x-4 mt-6">
              <SocialIcon icon={Facebook} href="https://www.facebook.com/littleallstarscarecenter" />
              <SocialIcon icon={Instagram} href="https://www.instagram.com/littleallstarscarecenter" />
              <SocialIcon icon={Linkedin} href="https://www.linkedin.com/in/littleallstarscarecenter" />
            </div>
          </div>

          {/* Columna 2: Enlaces Rápidos */}
          <div>
            <h4 className="text-xl font-semibold text-yellow-400 mb-6">{t('footer.quickLinks')}</h4>
            <ul className="space-y-3">
              <li><FooterLink href="about">
                {t('footer.aboutUs')}
              </FooterLink></li>
              <li><FooterLink href="services">
                {t('footer.services')}
              </FooterLink></li>
              <li><FooterLink href="activities">
                {t('footer.activities')}
              </FooterLink></li>
              <li><FooterLink href="contact">
                {t('footer.contactUs')}
              </FooterLink></li>
            </ul>
          </div>

          {/* Columna 3: Contacto */}
          <div>
            <h4 className="text-xl font-semibold text-yellow-400 mb-6">{t('contact.title')}</h4>
            <ul className="space-y-3">
              <li className="flex items-center">
                <MapPin size={18} className="mr-3 text-yellow-400" />
                {t('contact.infoAddress')}
              </li>
              <li className="flex items-center">
                <Phone size={18} className="mr-3 text-yellow-400" />
                {t('contact.infoPhone')}
              </li>
              <li className="flex items-center">
                <Mail size={18} className="mr-3 text-yellow-400" />
                {t('contact.infoEmail')}
              </li>
            </ul>
            <div className="mt-6 p-4 bg-indigo-700 rounded-lg shadow-inner">
              <h5 className="font-semibold text-yellow-400 mb-2">{t('footer.hours')}</h5>
              <p>{t('footer.schedule')}</p>
            </div>
          </div>
        </div>

        {/* Newsletter Signup */}
        {/* <div className="mt-12 p-6 bg-indigo-700 rounded-lg shadow-lg">
          <h4 className="text-xl font-semibold text-yellow-400 mb-4">Únete a Nuestra Comunidad de Padres</h4>
          <p className="mb-4">Recibe consejos, actividades y noticias directamente en tu inbox.</p>
          <form className="flex flex-col sm:flex-row gap-4">
            <input
              type="email"
              placeholder="Tu correo electrónico"
              className="flex-grow px-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-400"
            />
            <button className="px-6 py-2 bg-yellow-400 text-indigo-900 rounded-full font-semibold hover:bg-yellow-300 transition-colors duration-300">
              Suscribirse
            </button>
          </form>
        </div> */}

        {/* Línea de copyright */}
        <div className="border-t border-indigo-700 mt-12 pt-8 text-sm text-center">
          © {new Date().getFullYear()} Little All Stars Care Center. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;