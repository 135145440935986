import React, { useState } from 'react';
import { FaChalkboardTeacher, FaUsers, FaAppleAlt, FaClipboardList } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción
import HeroSection from '../components/HeroSection';

const jobs = [
  {
    icon: <FaChalkboardTeacher className="text-4xl text-yellow-500 mb-4" />,
    titleKey: "jobs.preschoolTeachers.title",
    descriptionKey: "jobs.preschoolTeachers.description"
  },
  {
    icon: <FaUsers className="text-4xl text-yellow-500 mb-4" />,
    titleKey: "jobs.teacherAssistants.title",
    descriptionKey: "jobs.teacherAssistants.description"
  },
  {
    icon: <FaAppleAlt className="text-4xl text-yellow-500 mb-4" />,
    titleKey: "jobs.kitchenStaff.title",
    descriptionKey: "jobs.kitchenStaff.description"
  },
  {
    icon: <FaClipboardList className="text-4xl text-yellow-500 mb-4" />,
    titleKey: "jobs.administrativeStaff.title",
    descriptionKey: "jobs.administrativeStaff.description"
  }
];

const JobOpenings = () => {
  const { t } = useTranslation(); // Inicializa el hook de traducción
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const { name, email } = formData;
    const whatsappMessage = `Hola, soy ${name}, mi correo es ${email}. Adjunto mi CV para la solicitud.`;
    const whatsappLink = `https://wa.me/15704550519?text=${encodeURIComponent(whatsappMessage)}`;

    window.open(whatsappLink, '_blank');

    setFormData({ name: '', email: '' });
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('jobs.title')}
            description={t('jobs.description')}
          />
        </div>
      </section>
  
      {/* Vacantes disponibles */}
      <section className="py-16">
        <div className="container mx-auto px-4 grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {jobs.map((job, index) => (
            <div key={index} className="bg-white p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl">
              {job.icon}
              <h2 className="text-2xl font-semibold text-sky-600 mb-4">{t(job.titleKey)}</h2>
              <p className="text-gray-600">{t(job.descriptionKey)}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Formulario de aplicación */}
      <section className="max-w-5xl mx-auto bg-white p-8 rounded-lg shadow-lg mb-12 border-t-8 border-sky-800">
        <h2 className="text-4xl font-bold text-sky-800 mb-6 text-center">{t('jobs.applicationForm.title')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              {t('jobs.applicationForm.name')}
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder={t('jobs.applicationForm.namePlaceholder')}
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              {t('jobs.applicationForm.email')}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder={t('jobs.applicationForm.emailPlaceholder')}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="bg-yellow-400 hover:bg-yellow-300 text-black py-3 px-6 rounded-full font-semibold transition duration-300 ease-in-out">
            {t('jobs.applicationForm.submit')}
          </button>
        </form>
      </section>

      
    </div>
  );
};

export default JobOpenings;
