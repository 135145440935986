import React, { useEffect, useState } from 'react';
import { FaPaintBrush, FaTree, FaMusic, FaBookOpen } from 'react-icons/fa'; // Asegúrate de tener react-icons instalado
import { useTranslation } from 'react-i18next';
import HeroSection from '../components/HeroSection';

const ActivityCalendar = () => {
  const { t, i18n } = useTranslation();
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch('/data/blog/activities.json');
        if (!response.ok) throw new Error('Error al cargar las actividades');
        const data = await response.json();
        setActivities(data.activities);
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivities();
  }, []);

  // Mapeo de iconos
  const iconMapping = {
    FaPaintBrush: <FaPaintBrush className="text-4xl text-yellow-500 mb-4" />,
    FaTree: <FaTree className="text-4xl text-yellow-500 mb-4" />,
    FaMusic: <FaMusic className="text-4xl text-yellow-500 mb-4" />,
    FaBookOpen: <FaBookOpen className="text-4xl text-yellow-500 mb-4" />,
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('activityCalendar.title')}
            description={t('activityCalendar.heroText')}
          />
        </div>
      </section>

      {/* Listado de actividades */}
      <section className="max-w-6xl mx-auto grid md:grid-cols-2 gap-8 px-6 py-16">
        {activities.map((activity, index) => (
          <div 
            key={index} 
            className="bg-white p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl"
          >
            {iconMapping[activity.icon]} {/* Renderizar icono dinámicamente */}
            <h2 className="text-2xl font-semibold text-sky-600 mb-4">{activity.title[i18n.language]}</h2>
            <p className="text-gray-600 mb-2">{activity.description[i18n.language]}</p>
            <p className="text-gray-500">{t('activityCalendar.dateLabel')}: {activity.date[i18n.language]}</p>
          </div>
        ))}
      </section>
    </div>
  );
};

export default ActivityCalendar;
