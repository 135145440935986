import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HeroSection from './HeroSection'; // Asegúrate de importar correctamente tu HeroSection

const ArticleDetail = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const { id } = useParams();
    const [article, setArticle] = useState(null);

    useEffect(() => {
        fetch('/data/blog/articles.json')
            .then((response) => response.json())
            .then((data) => {
                const foundArticle = data.articles.find((a) => a.id === parseInt(id));
                setArticle(foundArticle || null);
            })
            .catch((error) => console.error('Error al cargar el artículo:', error));
    }, [id]);

    // Manejo de artículo no encontrado
    if (!article) {
        return <h2 className="text-center text-xl font-bold mt-10">Artículo no encontrado</h2>;
    }

    // Extracción de datos del artículo
    const title = article.title[i18n.language] || 'Título no disponible';
    const introduction = article.content.introduction[i18n.language] || 'Introducción no disponible';

    return (
        <div className='text-lg'>
            <section>
                <HeroSection
                    title={t('blog.title')}
                    description={t('blog.heroText')}
                />
            </section>
            <section className="max-w-5xl mx-auto p-6 py-12 text-lg bg-white shadow-lg rounded-lg">
                <h1 className="text-4xl font-bold mb-4 text-sky-700">{title}</h1>
                <p className="text-sm text-gray-500 mb-6 border-b border-gray-300 pb-2">
                    Fecha de publicación: <span className="font-medium">{article.date}</span>
                </p>
                <p className="text-lg text-gray-700 mb-4">{introduction}</p>

                {/* Renderizar el cuerpo del artículo */}
                {article.content.body.map((paragraph, index) => (
                    <div key={index} className="mb-6">
                        {paragraph.heading && (
                            <h2 className="text-2xl font-semibold mt-4 mb-2 text-sky-700">
                                {paragraph.heading[i18n.language] || 'Encabezado no disponible'}
                            </h2>
                        )}
                        {paragraph.text && (
                            <p className="text-gray-700 mb-4">
                                {paragraph.text[i18n.language] || 'Texto no disponible'}
                            </p>
                        )}
                        {paragraph.sections && paragraph.sections.map((section, idx) => (
                            <div key={idx} className="mt-4">
                                <h3 className="text-xl font-semibold text-sky-600">
                                    {section.subtitle[i18n.language] || 'Subtítulo no disponible'}
                                </h3>
                                <p className="text-gray-600">
                                    {section.text[i18n.language] || 'Texto no disponible'}
                                </p>
                            </div>
                        ))}
                    </div>
                ))}

                <h2 className="text-2xl font-semibold mt-4 mb-2 text-sky-700">
                    ¡Ven a visitarnos!
                </h2>
                <p className="text-gray-700 mb-4">
                    {article.content.body[article.content.body.length - 1]?.text[i18n.language] || 'Texto de invitación no disponible'}
                </p>

                {/* Información de contacto */}
                <div className="mt-8 border-t border-gray-300 pt-4">
                    <h3 className="text-lg font-semibold">Información de contacto</h3>
                    <p><strong>Dirección:</strong> {article.contact_information.address[i18n.language]}</p>
                    <p><strong>Teléfono:</strong> {article.contact_information.phone}</p>
                    <p><strong>Email:</strong> {article.contact_information.email}</p>
                </div>
            </section>
        </div>
    );
};

export default ArticleDetail;
