import React, { useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';

const navItems = [
  { path: '/', labelKey: 'navbar.home' },
  { path: '/about', labelKey: 'navbar.about' },
  { path: '/services', labelKey: 'navbar.services' },
  { path: '/jobs', labelKey: 'navbar.jobs' },
  { path: '/activities', labelKey: 'navbar.activities' },
  { path: '/blog', labelKey: 'navbar.blog' },
  { path: '/testimonials', labelKey: 'navbar.testimonials' },
  { path: '/contact', labelKey: 'navbar.contact' }
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="bg-white text-sky-700 border-b-2 border-sky-700 border-t-8 border-t-sky-900 shadow-md">
      <nav className="container mx-auto flex flex-col justify-between items-center p-4">
        <div>
          <img
            src="/text-title.png"
            alt={t('home.title')}
            className="mx-auto w-64 md:w-72 lg:w-96 bg-white py-4"
          />
        </div>
        <div className="flex flex-row-reverse gap-4 items-center justify-start">
          <div className="flex space-x-4 font-semibold">
            <button
              onClick={() => changeLanguage('es')}
              className="transition duration-300 rounded-md shadow-sm"
            >
              <img
                src="/icons/es.svg" // Ruta del SVG de la bandera de España
                alt="Cambiar a español"
                className="w-6 h-6 hover:scale-110 transition-transform"
              />
            </button>
            {/* <div>/</div> */}
            <button
              onClick={() => changeLanguage('en')}
              className="transition duration-300 rounded-md shadow-sm"
            >
              <img
                src="/icons/gb.svg" // Ruta del SVG de la bandera del Reino Unido
                alt="Switch to English"
                className="w-6 h-6 hover:scale-110 transition-transform"
              />
            </button>
          </div>
          <button
            className="md:hidden p-2 rounded focus:outline-none focus:ring focus:ring-yellow-300 transition duration-300"
            onClick={toggleMenu}
            aria-label="Toggle navigation"
            aria-expanded={isOpen}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              )}
            </svg>
          </button>

          <ul className={`w-full md:w-auto flex-col md:flex md:flex-row md:items-center md:space-x-4 text-lg font-bold transition duration-300 ${isOpen ? 'flex' : 'hidden'}`}>
            {navItems.map(({ path, labelKey }) => (
              <li key={path} className="relative group">
                <NavLink
                  to={path}
                  className={({ isActive }) =>
                    path === '/'
                      ? `block py-2 md:py-0 px-4 rounded-md mb-2 ${
                          isActive ? "text-red-600" : "hover:text-red-500"
                        } transition duration-300`
                      : `block py-2 md:py-0 px-4 rounded-md mb-2 ${
                          isActive ? "text-yellow-600" : "hover:text-yellow-500"
                        } transition duration-300`
                  }
                  onClick={closeMenu}
                >
                  {t(labelKey)}
                </NavLink>
                <span className="absolute bottom-0 left-0 w-full h-1 bg-red-600 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
