import React from 'react';
import { FaAppleAlt, FaLightbulb, FaPlay, FaPencilAlt } from 'react-icons/fa'; // Asegúrate de tener react-icons instalado
import { useTranslation } from 'react-i18next';
import HeroSection from '../components/HeroSection';
import ArticleList from '../components/ArticleList';

const Blog = () => {
  const { t } = useTranslation();
  

  return (
    <div className="bg-gray-50 ">

      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('blog.title')}
            description={t('blog.heroText')}
          />
        </div>
      </section>

      <ArticleList />
      

    </div>
  );
};

export default Blog;
