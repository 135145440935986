import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; // Importa el hook de traducción
import HeroSection from '../components/HeroSection';

const Contact = () => {
  const { t } = useTranslation(); // Inicializa el hook de traducción
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construir el enlace de WhatsApp
    const { name, email, phone, message } = formData;
    const whatsappMessage = `Hola, me llamo ${name}, mi correo es ${email}, mi teléfono es ${phone} y mi mensaje es: ${message}`;
    const whatsappLink = `https://wa.me/15704550519?text=${encodeURIComponent(whatsappMessage)}`;

    // Abrir el enlace de WhatsApp en una nueva pestaña
    window.open(whatsappLink, '_blank');

    // Limpiar el formulario después del envío
    setFormData({ name: '', email: '', phone: '', message: '' });
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <section>
        <div>
          <HeroSection
            title={t('contact.title')}
            description={t('contact.description')}
          />
        </div>
      </section>
      
      <div className='flex flex-col lg:flex-row gap-4'>
        {/* Formulario de contacto */}
        <section className="p-8 bg-gray-100 rounded-lg shadow-lg container mx-auto lg:px-64 lg:py-32 mt-10">
          <h1 className='py-1 my-6 font-bold text-3xl border-b border-gray-400'>{t('contact.formTitle')}</h1>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder={t('contact.formNamePlaceholder')}
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
            />
            <input
              type="email"
              name="email"
              placeholder={t('contact.formEmailPlaceholder')}
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
            />
            <input
              type="tel"
              name="phone"
              placeholder={t('contact.formPhonePlaceholder')}
              value={formData.phone}
              onChange={handleChange}
              required
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
            />
            <textarea
              name="message"
              placeholder={t('contact.formMessagePlaceholder')}
              value={formData.message}
              onChange={handleChange}
              required
              rows="4"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-sky-500"
            />
            <button type="submit" className="bg-sky-600 text-white py-3 px-6 rounded-lg hover:bg-sky-500 transition duration-300">
              {t('contact.submitButton')}
            </button>
          </form>
        </section>

        {/* Datos de contacto */}
        <section className="text-lg p-8 lg:p-16 lg:px-32 border-b-8 border-sky-800 border-t-8 bg-white text-gray-800 rounded-lg shadow-lg container mx-auto mt-10 mb-12">
          <h1 className='py-8'>{t('contact.infoText')}</h1>
          <h2 className="text-2xl font-bold mb-4">{t('contact.infoTitle')}</h2>
          <p className="mb-2">{t('contact.infoOrganization')}</p>
          <p className="mb-2">{t('contact.infoAddress')}</p>
          <p className="mb-2">{t('contact.infoPhone')} <a href="tel:570-455-0519" className="text-sky-600 hover:underline">570-455-0519</a></p>
          <p className="mb-2">{t('contact.infoEmail')} <a href="mailto:contact@littleallstarscarecenter.com" className="text-sky-600 hover:underline">contact@littleallstarscarecenter.com</a></p>
        </section>
      </div>
      
    </div>
  );
};

export default Contact;
