import React from 'react';
import { Facebook, Instagram, Linkedin } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const SocialButton = ({ icon: Icon, name, color, hoverColor, link }) => (
  <a
    href={link} // Usar el link real pasado como prop
    target="_blank" // Para abrir en una nueva pestaña
    rel="noopener noreferrer" // Seguridad para evitar accesos no deseados
    className={`flex items-center justify-center p-3 rounded-full ${color} ${hoverColor} text-white transition-all duration-300 transform hover:scale-110 hover:shadow-lg`}
    aria-label={`Visita nuestro perfil de ${name}`}
  >
    <Icon size={24} />
  </a>
);

const RedesSocialesCTA = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-red-400 py-12">
      <div className="container mx-auto px-4">
        <div className="bg-white rounded-3xl p-8 shadow-xl">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
            {t('home.callsToAction.followUs')}
          </h2>
          <div className="flex justify-center space-x-6">
            <SocialButton
              icon={Facebook}
              name="Facebook"
              color="bg-blue-600"
              hoverColor="hover:bg-blue-700"
              link="https://www.facebook.com/littleallstarscarecenter"
            />
            <SocialButton
              icon={Instagram}
              name="Instagram"
              color="bg-gradient-to-br from-purple-600 via-pink-600 to-orange-500"
              hoverColor="hover:from-purple-700 hover:via-pink-700 hover:to-orange-600"
              link="https://www.instagram.com/littleallstarscarecenter"
            />
            <SocialButton
              icon={Linkedin}
              name="LinkedIn"
              color="bg-blue-700"
              hoverColor="hover:bg-blue-800"
              link="https://www.linkedin.com/in/littleallstarscarecenter"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedesSocialesCTA;
